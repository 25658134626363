import { useMutation, UseMutationResult } from 'react-query'

import { useUserReadingAction } from './useUserReadingAction'

export const useRemoveUserReading = (
  onSuccess?: () => Promise<any>
): UseMutationResult<any, unknown, any, unknown> => {
  const userReadingClient = useUserReadingAction()

  return useMutation(id => userReadingClient.removeUserReading(id), {
    onSuccess,
  })
}
