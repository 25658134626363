/* eslint-disable max-classes-per-file */
import { BookStatusEnum } from '@interfaces/BookStatusEnum'

class ChapterType {
  id: number

  chapterName: string

  status: BookStatusEnum
}

class UserType {
  id: number

  profilePageSlug: string
}

class PenNameType {
  user: UserType

  firstPenName: string
}

class BookType {
  id: number

  title: string

  writer: string

  status: BookStatusEnum

  coverResizeImgPath: string

  coverImgPath: string

  penName: PenNameType
}

export class ContinueToReadType {
  id: number

  updatedAt: string

  chapter: ChapterType

  book: BookType
}
