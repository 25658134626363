import { gql } from 'graphql-request'

export const GET_PREVIOUS_AND_NEXT_BOOK_ID = gql`
  query GetPreviousAndNextBookId($id: Float!) {
    getPreviousAndNextBookId(id: $id) {
      nextBookId
      previousBookId
    }
  }
`
