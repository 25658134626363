import { Type } from 'class-transformer'
import { ContinueToReadType } from './ContinueToReadType'

export class ContinueToReadResponse {
  page: number

  total: number

  @Type(() => ContinueToReadType)
  data: ContinueToReadType[]
}
