import { plainToInstance } from 'class-transformer'

import { CREATE_USER_READING } from '@client/collections/UserReading/schemas/createUserReading'
import { GET_PREVIOUS_AND_NEXT_BOOK_ID } from '@client/collections/UserReading/schemas/getPreviousAndNextBookId'
import { READ_NOW } from '@client/collections/UserReading/schemas/readNow'
import { REMOVE_USER_READING } from '@client/collections/UserReading/schemas/removeUserReading'
import { USER_READINGS } from '@client/collections/UserReading/schemas/userReadings'
import { gqlApiInstance } from '@client/init'
import { PreviousAndNextBookIdType } from '@models/userReading/PreviousAndNextBookIdType'
import { ReadNowType } from '@models/userReading/ReadNowType'
import { ContinueToReadResponse } from '@models/userReading/ContinueToReadResponse'

export function useUserReadingAction() {
  async function userReadings(params: {
    page: number
    limit: number
    sort: string
  }): Promise<ContinueToReadResponse> {
    const res = await gqlApiInstance.request(USER_READINGS, {
      limitPerPage: params.limit,
      page: params.page,
      sort: params.sort,
    })
    return plainToInstance(ContinueToReadResponse, res.userReadings)
  }

  async function createUserReading(
    chapterPageId: number
  ): Promise<{ id: number; updatedAt: string }> {
    const res = await gqlApiInstance.request(CREATE_USER_READING, {
      createUserReadingInput: {
        chapterPageId,
      },
    })

    return res.createUserReading
  }

  async function removeUserReading(id: number): Promise<any> {
    const res = await gqlApiInstance.request(REMOVE_USER_READING, {
      id,
    })
    return res.removeUserReading
  }

  async function readNow(
    bookId: number,
    bookChapterId?: number
  ): Promise<ReadNowType> {
    const res = await gqlApiInstance.request(READ_NOW, {
      bookId,
      bookChapterId,
    })
    return plainToInstance(ReadNowType, res.readNow)
  }

  async function getPreviousAndNextBookId(
    id: number
  ): Promise<PreviousAndNextBookIdType> {
    const res = await gqlApiInstance.request(GET_PREVIOUS_AND_NEXT_BOOK_ID, {
      id,
    })
    return res.getPreviousAndNextBookId
  }

  return {
    userReadings,
    createUserReading,
    removeUserReading,
    readNow,
    getPreviousAndNextBookId,
  }
}
