import { gql } from 'graphql-request'

export const CREATE_USER_READING = gql`
  mutation CreateUserReading($createUserReadingInput: UserReadingInput!) {
    createUserReading(createUserReadingInput: $createUserReadingInput) {
      id
      updatedAt
    }
  }
`
