import React from 'react'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'

import { diffNow } from '@lib/utils'
import { BinIcon } from '@components/icons/BinIcon'
import { ClockIcon } from '@components/icons/ClockIcon'
import { PenIcon } from '@components/icons/PenIcon'
import { ContinueToReadCardProps } from './interface'

export default function ContinueToReadCard({
  id,
  className,
  chapterName,
  bookWriterLabel = 'นามปากกา',
  bookCoverImgPath,
  bookTitle,
  bookWriter,
  updatedAt,
  direction = 'vertical',
  url,
  slug,
  showContinueToRead = false,
  icon = <ClockIcon className='w-[18px] desktop:mr-[5px]' />,
  dataTest,
  writerUserId,
  handleRemove,
}: ContinueToReadCardProps) {
  return (
    <div
      data-test={dataTest}
      className={cn(
        'rounded-[8px] shadow border-[0.5px] border-gray-4 flex shrink-0 overflow-hidden',
        className,
        {
          'desktop:p-[15px] tablet-mobile:flex-col tablet-mobile:p-[10px]':
            direction === 'vertical',
          'p-[15px] mobile:first:ml-[21px]': direction === 'horizontal',
        }
      )}
    >
      <Link href={url} prefetch={false} legacyBehavior>
        <a>
          <div
            className={cn('rounded-[8px] mr-[20px] overflow-hidden shrink-0', {
              'w-[80px]': direction === 'horizontal',
              'desktop:w-[80px] tablet-mobile:w-full tablet-mobile:mr-0':
                direction === 'vertical',
            })}
          >
            <div className='relative pt-[140.625%] w-full h-full bg-gray-3'>
              {bookCoverImgPath ? (
                <Image
                  src={bookCoverImgPath}
                  alt={bookTitle}
                  layout='fill'
                  sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                  quality={20}
                />
              ) : null}
            </div>
          </div>
        </a>
      </Link>
      <div
        className={cn(
          'w-full bg-white flex flex-col justify-between space-y-[5px]',
          {
            'tablet-mobile:p-0 tablet-mobile:mt-[8px]':
              direction === 'vertical',
            // 'tablet-mobile:p-[10px]': direction === 'horizontal',
          }
        )}
      >
        <div>
          <Link href={url} prefetch={false} legacyBehavior>
            <a>
              <div
                data-test='title'
                className='text-secondary font-mitr text-base font-medium line-clamp-1 break-all tablet-mobile:text-sm'
              >
                {bookTitle}
              </div>
            </a>
          </Link>

          <div
            className={cn(
              'grid grid-cols-[18px_1fr] gap-x-[5px] items-center text-[14px] mobile:text-[12px] font-light',
              {
                'mt-[5px] tablet-mobile:mt-[10px]': direction === 'vertical',
                'mt-[5px]': direction === 'horizontal',
              }
            )}
          >
            <PenIcon className='w-[18px] h-[18px] text-secondary-1' />
            <Link
              href={
                bookWriterLabel === 'นามปากกา'
                  ? `/profile/${slug}?tab=penName&text=${encodeURIComponent(
                      bookWriter
                    )}`
                  : `/profile/${slug}`
              }
              legacyBehavior
              prefetch={false}
            >
              <a
                data-test={
                  bookWriterLabel === 'นามปากกา'
                    ? 'pen-name'
                    : 'user-or-publisher'
                }
                className='text-secondary hover:underline truncate'
              >
                {bookWriter}
              </a>
            </Link>
          </div>
        </div>
        <div className='text-sm mobile:text-[12px] font-light grid tablet-mobile:grid-cols-[1fr_32px] gap-x-[10px] justify-between'>
          <div className='grid grid-cols-[33px_1fr] gap-x-[5px] items-center'>
            <div className='text-secondary-1'>ตอน :</div>
            <div className='text-secondary truncate'>{chapterName}</div>
          </div>
          {/* <div
            data-test='delete-continue-to-read-card'
            className='p-[5px] rounded-[8px] border-[1px] border-gray-3 cursor-pointer desktop:hidden'
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              handleRemove(id)
            }}
          >
            <BinIcon className='w-[20px] h-[20px] text-secondary-1' />
          </div> */}
        </div>
        <div className='flex items-center justify-between truncate desktop:hidden'>
          <div className='flex items-center gap-x-[5px]'>
            {icon}
            <span className='text-[12px] font-light text-secondary-1'>
              {diffNow(updatedAt, 'ที่ผ่านมา', true)}
            </span>
          </div>

          <button
            type='button'
            className='p-[5px] rounded-[8px] border-[1px] border-gray-3 cursor-pointer desktop:hidden'
            onClick={() => {
              handleRemove(id)
            }}
          >
            <BinIcon className='w-[20px] h-[20px] text-secondary-1' />
          </button>
        </div>
        <div className='flex items-center tablet-mobile:hidden'>
          {showContinueToRead && (
            <Link href={url} prefetch={false} legacyBehavior>
              <a className='font-mitr underline font-medium text-sm mr-[10px] text-primary select-none'>
                อ่านต่อเลย
              </a>
            </Link>
          )}
          {icon}
          <span
            className='text-sm font-light flex-1 text-secondary-1'
            data-test='read-time'
          >
            {diffNow(updatedAt, 'ที่ผ่านมา', true)}
          </span>
          <button
            type='button'
            data-test='delete-continue-to-read-card'
            className='p-[5px] rounded-[8px] border-[1px] border-gray-3 cursor-pointer'
            onClick={() => {
              handleRemove(id)
            }}
          >
            <BinIcon className='w-[20px] h-[20px] text-secondary-1' />
          </button>
        </div>
      </div>
    </div>
  )
}
