import React from 'react'
import { ContinueToReadCardProps } from './interface'

export default function withContinueToReadCard(
  Component: React.FC<ContinueToReadCardProps>
) {
  function Hoc(props: ContinueToReadCardProps) {
    return <Component {...props} />
  }

  return Hoc
}
